import { template as template_bba1d4b522594c1f9227b270e44748c5 } from "@ember/template-compiler";
const FKLabel = template_bba1d4b522594c1f9227b270e44748c5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
