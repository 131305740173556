import { template as template_14965cb1b3c44cde9e93467d553de3fc } from "@ember/template-compiler";
const SidebarSectionMessage = template_14965cb1b3c44cde9e93467d553de3fc(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
