import { template as template_2b2fb20ece77465484d2b7b6809854a1 } from "@ember/template-compiler";
const FKText = template_2b2fb20ece77465484d2b7b6809854a1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
